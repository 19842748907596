import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";

function SignIn() {
  const [enter, setEnter] = useState(true);

  function handleLogin() {
    setEnter((prev) => !prev);
  }
  return (
    <>
      {enter && <Login change={handleLogin} />}
      {!enter && <Register change={handleLogin} />}
    </>
  );
}

export default SignIn;
