import React, { useState, useEffect, useCallback } from "react";
import "./Photos.css";

import frontView from "./frontview.jpg";

import hostelFront from "./hostel-front.jpg";

const Photos = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = images.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img.url;
          image.onload = resolve;
          image.onerror = reject;
        });
      });

      await Promise.all(imagePromises);
      // setLoading(false);
    };

    loadImages();
  }, [images]);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const handleCloseModal = useCallback((e) => {
    if (e.target.classList.contains("modal")) {
      setSelectedImage(null);
    }
  }, []);

  const handlePrevImage = useCallback(() => {
    setSelectedImage((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  }, [images.length]);

  const handleNextImage = useCallback(() => {
    setSelectedImage((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  }, [images.length]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (selectedImage !== null) {
        if (e.key === "ArrowLeft") {
          handlePrevImage();
        } else if (e.key === "ArrowRight") {
          handleNextImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedImage, handlePrevImage, handleNextImage]);

  //   if (loading) {
  //     return <div className="loading">Loading...</div>;
  //   }

  return (
    <div className="photos-container">
      <h2 className="photos-header">Photos </h2>
      {/* <button className="view-all-btn">View all photos</button> */}
      <div className="photo-grid">
        {images.map((img, index) => (
          <div
            key={index}
            className="photo-item"
            onClick={() => handleImageClick(index)}
          >
            <img src={img.url} alt={img.alt} />
            <div className="overlay">
              <span className="zoom-icon">🔍</span>
            </div>
          </div>
        ))}
      </div>
      {selectedImage !== null && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content">
            <img
              src={images[selectedImage].url}
              alt={images[selectedImage].alt}
            />
            <button
              className="close-btn"
              onClick={() => setSelectedImage(null)}
            >
              &times;
            </button>
            <button className="nav-btn prev" onClick={handlePrevImage}>
              &#10094;
            </button>
            <button className="nav-btn next" onClick={handleNextImage}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Photos;

// Test data
export const testImages = [
  { url: frontView, alt: "Modern loft with stairs" },
  { url: hostelFront, alt: "Cozy bedroom with blue accents" },
  { url: frontView, alt: "Stylish bedroom with round mirror" },
  // { url: "./test14.png", alt: "Contemporary kitchen with island" },
];
