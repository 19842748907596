import React, { useState } from "react";
import "./HostelRules.css"; // Make sure this path is correct

import allRules from "./allrules";

const HostelRules = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="hostel-rules-container">
      <h2 className="hostel-rules-title">Hostel Rules</h2>
      <div className="hostel-rules-accordion">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`hostel-rules-button ${isOpen ? "" : "closed"}`}
        >
          View Hostel Rules
          <span className={`hostel-rules-arrow ${isOpen ? "open" : ""}`}>
            ▼
          </span>
        </button>
        {isOpen && (
          <div className="hostel-rules-content">
            <ul className="hostel-rules-list">
              {allRules.map((rule, index) => (
                <li key={index} className="hostel-rules-item">
                  {rule}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HostelRules;
