import "./header.css";

import { useContext } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Header = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <div className="hero">
        <div className="hero_content">
          <h1 className="hero-header">
            Showers Hostel
            {/* <br />
            architecture */}
          </h1>

          {!user ? (
            <div>
              <h5 className="info-sub">
                Kindly Create an account to book rooms{" "}
              </h5>
              <Link to="/login">
                <button className=" explore headerBtn">
                  Sign in / Register
                </button>
              </Link>
            </div>
          ) : (
            <Link to="/rooms" className="explore_link">
              <button className="headerBtn explore">
                Check room availability
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
