import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./register.css";

function Register({ change }) {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Register | Showers Hostel";
  }, []);

  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);

  const steps = [
    {
      title: "Personal Information",
      fields: ["firstname", "lastname", "usernamei", "phone", "sex"],
    },
    {
      title: "Academic Details",
      fields: ["level_", "email", "course"],
    },
    {
      title: "Account Setup",
      fields: ["passwordn", "file"],
    },
  ];

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(null);
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    dispatch({ type: "LOGIN_START" });

    const data = new FormData();
    data.append("image", file);

    try {
      const imgbbResponse = await fetch(
        "https://api.imgbb.com/1/upload?key=1990469f9237fb59e7268465029982fa",
        {
          method: "POST",
          body: data,
        }
      );

      const imgbbData = await imgbbResponse.json();

      if (imgbbData.status === 200) {
        const newUser = {
          ...info,
          img: imgbbData.data.url,
        };

        const apiEndpoint = "api/auth/register";
        try {
          const response = await axios.post(apiEndpoint, newUser);
          dispatch({ type: "LOGIN_SUCCESS", payload: response.data.details });

          toast.success(`Account Created successfully, Redirecting .....`);
          setTimeout(() => navigate("/"), 5000);
        } catch (err) {
          dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
        }
      } else {
        throw new Error("Image upload failed");
      }
    } catch (err) {
      console.error("Error during registration:", err);
      toast.error(err?.message || "An error occurred");
      setError(err.message || "An error occurred during registration");
    } finally {
      setLoading(false);
    }
  };

  const isStepComplete = (step) => {
    return steps[step].fields.every(
      (field) => info[field] || (field === "file" && file)
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="register-container">
        <form id="msform" className="form sign-up">
          {loading && (
            <DotLottieReact
              className="loading-animation"
              loop
              autoplay
              src="https://lottie.host/d259d658-b051-44e7-9469-1521ae8abeae/r9TTOPpyVp.lottie"
            />
          )}
          <h2 className="form-title">{steps[currentStep].title}</h2>

          <ul id="progressbar">
            {steps.map((step, index) => (
              <li key={index} className={index <= currentStep ? "active" : ""}>
                {step.title}
              </li>
            ))}
          </ul>

          <fieldset>
            {steps[currentStep].fields.map((field, index) => (
              <div key={index} className="form-field">
                {field === "file" ? (
                  <div className="file-upload">
                    <label htmlFor="file">
                      Profile Image <CloudUploadIcon className="icon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="img"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="profile-picture preview"
                      />
                    )}
                    {file && <span>{file.name}</span>}
                  </div>
                ) : field === "sex" ? (
                  <div className="select-wrapper">
                    <label htmlFor="sex">Gender:</label>
                    <select
                      name="sex"
                      id="sex"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                ) : field === "level_" ? (
                  <div className="select-wrapper">
                    <label htmlFor="level_">Level:</label>
                    <select
                      name="level_"
                      id="level_"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Level</option>
                      <option value="Undergraduate">
                        Undergraduate (100 - 400)
                      </option>
                      <option value="Postgraduate">Postgraduate</option>
                    </select>
                  </div>
                ) : field === "usernamei" ? (
                  <div className="input-wrapper">
                    <label htmlFor="usernamei">Username</label>
                    <input
                      type="text"
                      id={field}
                      placeholder={`Enter Username`}
                      onChange={handleChange}
                      className="lInput"
                      required
                    />
                  </div>
                ) : field === "passwordn" ? (
                  <div className="input-wrapper">
                    <label htmlFor="passwordn">Password</label>
                    <input
                      type={"passwordn"}
                      id={field}
                      placeholder={`Create a password`}
                      onChange={handleChange}
                      className="lInput"
                      required
                    />
                  </div>
                ) : (
                  <div className="input-wrapper">
                    <label htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1)}:
                    </label>
                    <input
                      type={field === "email" ? "email" : "text"}
                      id={field}
                      placeholder={`Enter ${field}`}
                      onChange={handleChange}
                      className="lInput"
                      required
                    />
                  </div>
                )}
              </div>
            ))}
          </fieldset>

          <div className="form-actions">
            {currentStep > 0 && (
              <button
                type="button"
                className="action-button previous"
                onClick={handlePrevious}
              >
                Previous
              </button>
            )}
            {currentStep < steps.length - 1 ? (
              <button
                type="button"
                className="action-button next"
                onClick={handleNext}
                disabled={!isStepComplete(currentStep)}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                onClick={handleClick}
                className="action-button submit"
                disabled={!isStepComplete(currentStep) || loading}
              >
                {loading ? "Please Wait" : "Sign Up"}
              </button>
            )}
          </div>
          {currentStep === 0 && (
            <button onClick={change} className="login-link" type="button">
              Already have an account? Login
            </button>
          )}
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
}

export default Register;
