import Faq from "../../components/FAQs/Faq";
// import Footer from "../../components/footer/Footer";
import { useEffect } from "react";
import Header from "../../components/header/Header";
import InfoBooking from "../../components/infoBooking/InfoBooking";
import InfoSection from "../../components/infoSection/InfoSection";
import MailList from "../../components/mailList/MailList";
import Usernavber from "../../components/navbar/Navbar";
import Photos, { testImages } from "../../components/Photos/Photos";
import HostelRules from "../../components/Rules/HostelRules";
import "./home.css";

const Home = () => {
  useEffect(() => {
    document.title = "Home | Showers Hostel";
  }, []);
  return (
    <>
      <Usernavber />
      <Header />
      <div className="homeContainer">
        <InfoBooking />
        <InfoSection />
        <Photos images={testImages} />
        <HostelRules />
        <Faq />
        <MailList />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Home;
