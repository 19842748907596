import React from "react";
import "./infosection.css";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import MapIcon from "@mui/icons-material/Map";
import LuggageIcon from "@mui/icons-material/Luggage";
import topview from "./topview.jpg";
import { Link } from "react-router-dom";

const InfoSection = () => {
  return (
    <div className="hotel-info-container">
      <div className="text-section">
        <h1 className="text-setion-title">We have everything you need</h1>
        <p>
          All amenities to make your stay here a good, memorable and stress free
          !
        </p>
        <div className="features">
          <div className="feature">
            <span className="icon wifi-icon">
              <LiveTvIcon />
            </span>
            <span>Free DSTV Available</span>
          </div>
          <div className="feature">
            <span className="icon location-icon">
              <MapIcon />
            </span>
            <span>Convenient location in the center</span>
          </div>
          <div className="feature">
            <span className="icon luggage-icon">
              <LuggageIcon />
            </span>
            <span>Free storage of luggage of any size</span>
          </div>
        </div>
        <div className="buttons">
          <Link to="/#faq">
            {" "}
            <button className="book-now">More Info</button>
          </Link>
        </div>
      </div>
      <div className="image-section">
        <img src={topview} alt="Happy guests in hotel" />
      </div>
    </div>
  );
};

export default InfoSection;
