import React, { useState } from "react";
import "./Faqs.css";

const FaqItem = ({ id, question, answer, isOpen, toggleOpen }) => {
  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <div className="faq-question" onClick={() => toggleOpen(id)}>
        <h3>
          {question} <span className="arrow-faq">{isOpen ? "▲" : "▼"}</span>
        </h3>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const AskQuestionBox = () => {
  return (
    <div className="ask-question-box">
      <h3>Do you have any questions?</h3>
      <p className="question-faq">
        Diam phasellus vestibulum lorem sed risus ultricies tristique
      </p>
      <a href="/profile">
        <button className="ask-question-btn">Ask a question</button>
      </a>
    </div>
  );
};

const Faqs = () => {
  const [openItems, setOpenItems] = useState({});

  const faqData = [
    {
      id: 1,
      question: "What amenities are typically provided?",
      answer:
        "We offer basic amenities such as free Wi-Fi, shared kitchens, common areas, lockers, and organized activities or tours.",
    },
    {
      id: 2,
      question: "How many people are in a hostel room?",
      answer:
        "The number of people in a hostel room can vary. Typically, hostel rooms accommodate 1 to 3 people.",
    },
    {
      id: 3,
      question: "Are there private rooms in Hostels?",
      answer:
        "Yes, these rooms can be single, double, or even tripple, providing more privacy for those who prefer it.",
    },
    {
      id: 4,
      question: "How do I keep my things safe in a hostel?",
      answer:
        "Most hostels provide lockers for guests to store valuables. It's advisable to bring your own lock. Keep important documents and expensive items locked up when not in use. Be aware of your surroundings and use common sense.",
    },
    {
      id: 5,
      question: "How do you stay safe in a hostel?",
      answer:
        "To stay safe in a hostel, be aware of your surroundings, lock up valuables, trust your instincts, and get to know your roommates. Most hostels have security measures in place, but it's always good to be cautious.",
    },
  ];

  const toggleOpen = (id) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [id]: !prevOpenItems[id],
    }));
  };

  return (
    <div className="faqs-container" id="faq">
      <h2 className="faqs-title">Frequently asked questions.</h2>
      <p className="faqs-subtitle">
        These are some frequently asked questions asked about our hostel.
      </p>
      <div className="faqs-grid">
        {faqData.map((faq) => (
          <FaqItem
            key={faq.id}
            id={faq.id}
            question={faq.question}
            answer={faq.answer}
            isOpen={openItems[faq.id] || false}
            toggleOpen={toggleOpen}
          />
        ))}
        <AskQuestionBox />
      </div>
    </div>
  );
};

export default Faqs;
