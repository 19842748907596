import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./login.css";

const Login = ({ change }) => {
  useEffect(() => {
    document.title = "Login | Showers Hostel";
  }, []);

  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/api/auth/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
      navigate("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <span className="login100-form-title p-b-34 p-t-27">
                SHOWERS - HOSTEL <br />
                USER LOGIN
              </span>

              <div
                className="wrap-input100 validate-input"
                data-validate="Enter username"
              >
                <input
                  type="text"
                  id="username"
                  placeholder="username"
                  onChange={handleChange}
                  className="input100"
                  required
                />
                <span
                  className="focus-input100"
                  data-placeholder="&#xf207;"
                ></span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className="input100"
                  required
                />
                <span
                  className="focus-input100"
                  data-placeholder="&#xf191;"
                ></span>
              </div>
              <div className="error-msg">
                {" "}
                {error && <span className="txt1">{error.message}</span>}
              </div>
              <div className="container-login100-form-btn">
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={handleClick}
                  className="login100-form-btn"
                >
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </div>

              <div className="flex-col-c p-b-40">
                <span className="txt1 p-b-9">Don't have an account?</span>

                <a href="#/" className="txt3" onClick={change}>
                  Sign up now
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
