import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Usernavbar from "./navbar/Navbar";

const PaymentCallback = () => {
  const [status, setStatus] = useState("Processing...");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get("reference");

      if (reference) {
        try {
          const response = await axios.get(
            `/api/paystack/verify-transaction/${reference}`
          );
          if (response.data.verified) {
            setStatus("Payment successful! Redirecting...");
            setTimeout(() => navigate("/profile"), 3000);
          } else {
            setStatus("Payment failed. Please try again.");
            setTimeout(() => navigate("/rooms"), 3000);
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
          setStatus("An error occurred. Please contact support.");
        }
      } else {
        setStatus("Invalid payment reference.");
        setTimeout(() => navigate("/rooms"), 3000);
      }
    };

    verifyPayment();
  }, [location, navigate]);

  return (
    <div>
      <Usernavbar />
      <h2>Payment Status</h2>
      <p>{status}</p>
    </div>
  );
};

export default PaymentCallback;
