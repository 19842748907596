import React, { useContext, useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./navbar.css";

const Usernavbar = () => {
  const { user } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const dropdownRef = useRef(null);
  const prevScrollPos = useRef(0);
  function handleUser() {
    localStorage.setItem("user", null);
  }
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible =
        prevScrollPos.current > currentScrollPos || currentScrollPos < 10;

      setIsVisible(visible);
      prevScrollPos.current = currentScrollPos;
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className={`navbar ${isVisible ? "" : "navbar-hidden"}`}>
      <div className="navbar-container">
        <div
          className={`hamburger ${isSidebarOpen ? "open" : ""}`}
          onClick={toggleSidebar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo">Showers Hostel</div>
        <ul className="nav-links">
          <li>
            <a href="/home">Home</a>
          </li>
          <li>
            <a href="/home#faq">About</a>
          </li>
          <li>
            <a href="/profile#contact">Services</a>
          </li>
          <li>
            <a href="/profile#contact">Contact</a>
          </li>
        </ul>
        <div className="auth-section">
          {user ? (
            <div
              className="avatar"
              onClick={toggleDropdown}
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
              ref={dropdownRef}
            >
              <img src={user.img} alt="User Avatar" />
              <ul className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
                <li>
                  <a href="#profile">Signed in as {user.username}</a>
                </li>
                <li>
                  <a href="/profile">Settings</a>
                </li>
                <li>
                  <a href="/login" onClick={handleUser}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <a href="/login" className="signup-btn">
              Sign In
            </a>
          )}
        </div>
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <ul onClick={toggleSidebar}>
          <li>
            <a href="/home">Home</a>
          </li>
          <li>
            <a href="/home#faq">About</a>
          </li>
          <li>
            <a href="/profile#contact">Services</a>
          </li>
          <li>
            <a href="/profile#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Usernavbar;
