import React from "react";
import "./infoBooking.css";

import roomImage from "./book-a-room.jpg";
import { Link } from "react-router-dom";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";

const InfoBooking = () => {
  return (
    <div className="hostel-info-container">
      <div className="image-section-book">
        <img src={roomImage} alt="Happy guests in hotel" />
      </div>
      <div className="text-section-book">
        <h1 className="text-setion-title">Stages of booking a room</h1>
        <p>
          Kindly follow the instructions below to book a room. Note that you
          will only be able to book rooms that match you gender !
        </p>
        <div className="features-book">
          <div className="feature-book">
            <div className="book-rel">
              <span className="icon one-icon">
                <LooksOneIcon className="numbers" />
              </span>{" "}
            </div>
            <h3 className="section-points">
              Sign In or Sign Up if you dont have an account.
            </h3>
          </div>
          <div className="feature-book">
            <div className="book-rel">
              <span className="icon two-icon">
                <LooksTwoIcon className="numbers" />
              </span>
            </div>
            <h3 className="section-points">
              Select a room based on your criteria
            </h3>
          </div>
          <div className="feature-book">
            <span className="icon three-icon">
              <Looks3Icon className="numbers" />
            </span>
            <h3 className="section-points">Pay for the room !</h3>
          </div>
        </div>
        <div className="buttons">
          <Link to="/rooms">
            <button className="book-now">Choose room</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InfoBooking;
