import React, { useEffect, useState, useContext } from "react";
import HotelIcon from "@mui/icons-material/Hotel";
import useFetch from "../../hooks/useFetch";
import frontView from "./frontview.jpg";
import "./searchItem.css";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const SearchItem = ({ item }) => {
  const [details, setDetails] = useState({
    remaining: null,
    available: false,
    status: "Loading...",
    gender: "Male",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasBooked, setHasBooked] = useState(false);

  const { user } = useContext(AuthContext);

  const { data, loading } = useFetch(`api/bookings/status/${item.id}`);
  useEffect(() => {
    async function checkUserBooking() {
      if (user) {
        try {
          const response = await axios.get(`api/bookings/find/${user.id}`);
          setHasBooked(response.data.length > 0);
        } catch (error) {
          console.error("Error checking user booking:", error);
        }
      }
    }
    checkUserBooking();
  }, [user]);
  useEffect(() => {
    if (data && data.length > 0) {
      const { booking_count, booking_status, gender } = data[0];

      const calculatedRemaining = item.maxPeople - booking_count;
      setDetails({
        remaining: calculatedRemaining,
        available: calculatedRemaining > 0,
        status: booking_status,
        gender,
      });
    }
  }, [data, item.maxPeople]);

  const handleGenderCheck = async () => {
    if (!user) {
      setErrorMessage("Please login / Create an account to book a room.");
      return false;
    }

    try {
      const userResponse = await axios.get(`api/users/${user.id}`);
      const userData = await userResponse.data;
      const userSex = userData.sex === "M" ? "Male" : "Female";

      if (userSex !== details.gender) {
        setErrorMessage(
          `This Room is for ${details.gender.toUpperCase()}S only. Please select a different room.`
        );
        return false;
      }

      return true;
    } catch (err) {
      console.error("Error during the gender check:", err);
      setErrorMessage("An error occurred. Please try again later.");
      return false;
    }
  };

  const handlePaymentClick = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);

    const genderCheckPassed = await handleGenderCheck();
    if (genderCheckPassed) {
      try {
        const response = await axios.post(
          "api/paystack/initialize-transaction",
          {
            email: user?.email,
            amount: item.price * 100,
            currency: "GHS",
            metadata: {
              room_id: item.id,
              user_id: user?.id,
              max_people: item.maxPeople,
              gender: details.gender,
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.authorization_url
        ) {
          window.location.href = response.data.data.authorization_url;
        } else {
          setErrorMessage(
            "Failed to initialize transaction. Please try again."
          );
        }
      } catch (error) {
        console.error("Error initializing transaction:", error);
        setErrorMessage("An error occurred. Please try again later.");
      }
    }
    setIsLoading(false);
  };

  const amenities = JSON.parse(item.amenities);
  const allAmenities =
    Object.keys(JSON.parse(amenities)).join(", ") || "Bed Only";

  const isButtonDisabled =
    !details.available || loading || isLoading || !user || hasBooked;

  return (
    <div className="searchItem">
      <img src={frontView} alt="" className="siImg" />
      <div className="siDesc">
        <h1 className="siTitle">
          {item.maxPeople} in a room, with: {allAmenities}
        </h1>
        <div className="mini-details">
          <span className="siTaxiOp">{details.gender}</span>
          <span className="siFeatures">{item.description}</span>
          <span className="siCancelOp">
            <HotelIcon /> Beds left: {details.remaining}/{item.maxPeople}
          </span>
        </div>
      </div>
      <div className="siDetails">
        <div className="siDetailTexts">
          <span className="siPrice">GH₵{item.price}.00</span>
          <span className="siTaxOp">/ Per Person</span>
          {errorMessage && (
            <span className="error_message">{errorMessage}</span>
          )}
          {successMessage && (
            <span className="success_message">{successMessage}</span>
          )}
          <button
            onClick={handlePaymentClick}
            className={`siCheckButton ${
              isButtonDisabled ? "button_full" : "button_available"
            }`}
            disabled={isButtonDisabled}
          >
            {isLoading
              ? "Processing..."
              : loading
              ? "Loading data, please wait"
              : !user
              ? "Login to Book"
              : hasBooked
              ? "Already Booked a room"
              : "Pay Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
