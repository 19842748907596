import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/system";
import Home from "./pages/home/Home";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

import List from "./pages/list/List";
import SignIn from "./pages/login/SignIn";
import axios from "axios";
import UserProfile from "./pages/profile/UserProfile";
import PaymentCallback from "./components/PaystackCallback";
import { AuthContextProvider } from "./pages/profile/AuthContext";

// Set the base URL for Axios globally
axios.defaults.baseURL = "https://back.showers-hostel.com/";
// axios.defaults.baseURL = "http://localhost:8080/";

function App() {
  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <NextUIProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rooms" element={<List />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <AuthContextProvider>
                  <UserProfile />
                </AuthContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path="/payment-callback" element={<PaymentCallback />} />
          <Route path="/login" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
