const allRules = [
  "You are allowed to bring your own electronics. eg: A.C, TV, etc",
  "Quiet hours from 10 PM to 7 AM",
  "No smoking inside the building",
  "Keep common areas clean and tidy",
  "Respect other guests' privacy and personal space",
  "No illegal substances allowed on the premises",
  // "Check-out time is 11 AM",
  "Visitors must leave by 10 PM",
  "No pets allowed (except service animals)",
  "Lock your lockers and safeguard your belongings",
  "Alcohol consumption only in designated areas",
  "Dispose of trash in provided bins",
  "No cooking in dormitories",
  "Wash and dry dishes after use in common kitchen",
  "Report any damages or issues to staff immediately",
  "Observe fire safety regulations",
  "No loud music or excessive noise at any time",
  "Respect cultural differences and be inclusive",
];

export default allRules;
export const helpInfo = [
  `Click on "Sign in / Register" to get started.`,
  "Patiently wait for your account to be Logged in .",
  `Click on "Check Room Availability" or "Choose Room". And wait for the system to pick all available rooms.`,
  "You can filter through the results to display rooms of your choice",
  `NB: A room can be booked based on your gender, so choose accordingly.`,
  `Click "Pay Now" to book a room.`,
  `NB: If the "Pay Now" button is BLUE then the room is available for booking.`,
  `Enter your number to initiate payment.`,
  `Once the payment is successful, you will be redirected to the user dashboard where you will be able to view booking status, view roommates if any, and lodge complaints.`,
  "Keep in mind that you can access the system anytime by entering you email address and the password you chose during the registration process.",
  "For any queries or further assistance, send a message from your dashboard, our dedicated customer support team is ready to help",
  "Thank you for choosing our hostel; we look forward to making your stay comfortable and enjoyable",
];
