import "./mailList.css";
import { helpInfo } from "../Rules/allrules";
import { Link } from "react-router-dom";

const MailList = () => {
  return (
    <div className="mail">
      <h1 className="mailTitle">System Help and Instructions</h1>
      <ul className="hostel-helpInfo-list">
        {helpInfo.map((help, index) => (
          <li key={index} className="hostel-helpInfo-item">
            {help}
          </li>
        ))}
      </ul>
      <Link to="/rooms">
        <button>Choose Room</button>
      </Link>
    </div>
  );
};

export default MailList;
