import "./list.css";
import Navbar from "../../components/navbar/Navbar";

import { useEffect, useState } from "react";
import SearchItem from "../../components/searchItem/SearchItem";
import axios from "axios";

const List = () => {
  const [maxPeople, setMaxPeople] = useState(1);
  const [gender, setGender] = useState("Male");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const amenitiesOptions = ["wardrobe", "bathroom", "toilet"];

  useEffect(() => {
    document.title = "Rooms | Showers Hostel";
    const fetchRooms = async () => {
      try {
        setLoading(true);
        const response = await axios.get("api/rooms/");
        setRooms(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching rooms:", error);
        setError("Failed to fetch rooms. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchRooms();
  }, []);

  const handleAmenityChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAmenities((prevAmenities) =>
      checked
        ? [...prevAmenities, value]
        : prevAmenities.filter((amenity) => amenity !== value)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get("api/rooms/specific/all/", {
        params: {
          maxPeople,
          gender,
          amenities: selectedAmenities.join(","),
        },
      });
      setRooms(response.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching rooms:", error);
      setError("Failed to fetch rooms. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      {/* <Header type="list" /> */}
      <div className="listContainer">
        <div className="listWrapper">
          <form onSubmit={handleSubmit} className="room-search-form">
            <h2 className="form-title">Room Search</h2>

            <div className="form-group">
              <label htmlFor="maxPeople">Max People:</label>
              <select
                id="maxPeople"
                name="maxPeople"
                value={maxPeople}
                onChange={(e) => setMaxPeople(parseInt(e.target.value, 10))}
                required
              >
                <option value={1}>1 Person</option>
                <option value={2}>2 People</option>
                <option value={3}>3 People</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="gender">Gender:</label>
              <select
                id="gender"
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="form-group">
              <label>Amenities:</label>
              <div className="amenities-group">
                {amenitiesOptions.map((amenity) => (
                  <div key={amenity} className="amenity-item">
                    <input
                      type="checkbox"
                      id={`amenity-${amenity}`}
                      value={amenity}
                      onChange={handleAmenityChange}
                    />
                    <label htmlFor={`amenity-${amenity}`}>
                      {amenity.charAt(0).toUpperCase() + amenity.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? "Searching..." : "Search"}
            </button>
          </form>
          <div className="listResult">
            <p className="rules">
              Please make sure you have read and understood the{" "}
              <a href="/hostel-rules">rules of the Hostel</a> before proceeding
              to book a room
            </p>
            <h3>ALL ROOMS</h3>

            {loading ? (
              <p>Loading rooms...</p>
            ) : error ? (
              <p>{error}</p>
            ) : rooms.length > 0 ? (
              rooms.map((room) => <SearchItem item={room} key={room.id} />)
            ) : (
              <p>
                No room is available.
                <br /> Change the room Criteria and press Search
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
